<template>
  <div class="indexbox1">
    <!-- 顶部 -->
    <topheader :type="activeIndex"></topheader>
    <div class="main">
      <!-- 热门 -->
      <div class="hotbox flex_center"  >
        <div class="bigbig flex_center"  
              @mouseenter="changeLeft" 
              @mouseleave="changeLeftBack"
           >
            <div class="hotbox_left">
            <div>热门领域</div>
            <p 
              v-for="(item,i) in LawKnowledgelist" :key="i"
              :class="[hottype == i ? 'active' : '']"
              @mouseenter="changeChan(i, item.id,item.name)" 
              >
              <img :src="item.photo" alt="">
              {{item.name}}
            </p>
          </div>
          <div class="hotbox_center">
            <!-- <img src="@/assets/img/bg1.png" alt="" class="bgimg" v-if="!ishotleft"> -->
            <div v-if="!ishotleft">
              <el-carousel  height="436px">
                <el-carousel-item v-for="(data,num) in swipers" :key="num">
                    <img :src="data.image" alt=""  class="bgimg">
                </el-carousel-item>
              </el-carousel>
            </div>

            <div class="hotbox_center_main" v-else>
              <p class="ly_title">{{hottitle}}</p>
              <div class="sapnbox">
                <a href="javascript:;" v-for="(item,i) in hotlist" :key="i" @click.stop="tourllydetails(item)">{{item.name}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="hotbox_right">
          <div class="btnbox zixun" @click.stop="tourl('/toask')">快速咨询</div>
          <img src="@/assets/img/bgright.png" alt="" class="bgright">
        </div>
      </div>
      <!-- 服务保障 -->
      <div class="fubox">
        <div class="base_w flex_around">
          <img src="@/assets/img/f1.png" alt="">
          <img src="@/assets/img/f2.png" alt="">
          <img src="@/assets/img/f3.png" alt="">
          <img src="@/assets/img/f4.png" alt=""> 
        </div>
      </div>
      <!-- 金牌律师 -->
      <jinpails></jinpails>
      <!-- 免费律师在线 -->
      <div class="mfbox">
        <div class="base_w flex_between"> 
          <div class="mfboxleft common_index_leftwidth">
            <div class="common_title_box">
              <span class="title">免费律师在线</span>
            </div>
            <p class="text">有法律疑惑，马上免费在线咨询，您的法律疑问我们来解决</p>
            <zixun :type="1" ref="zixunwen"></zixun>
          
          </div>
          <div class="mfboxright common_index_rightwidth">
            <div class="zixunbo" @click.stop="tourl('/toask')">
              <div class="zaixian">在线咨询 >> </div>
              <img src="@/assets/img/icon_guang1.png" alt="">
            </div>
          </div>
        </div>
      </div>

      <!-- 最新咨询和最新评价 -->
      <div class="zxbox">
        <div class="base_w flex">
          <div class="common_index_leftwidth">
            <div class="common_title_box" @click.stop="tourl('/typedetails')">
              <span class="title">最新咨询</span>
              <div class="righttext">
                  更多
                  <img src="@/assets/img/right.png" alt="" class="iconimg">
              </div>
            </div>
            <div class="qu">
              <wenda ref="newwenda" :wendalistArr="wendalist"></wenda>
            </div>
          </div>
          <div class="common_index_rightwidth">
            <div class="common_title_box">
              <span class="title">最新评价</span>
            </div>
            <pingjia ref="newpingjia" :list="pingjialist"></pingjia>
          </div>
        </div>
       
      </div>

      <!-- 热门资讯和知识普法 -->
      <div class="rmbox">
        <div class="base_w flex">
          <div class="common_index_leftwidth">
            <div class="common_title_box">
              <span class="title">热门资讯</span>
              <div class="righttext" @click.stop="tourl('/infolist')">
                  更多
                  <img src="@/assets/img/right.png" alt="" class="iconimg">
              </div>
            </div>
            <div class="rmzxbox"> 
              <news :list="informationlist"></news>
            </div>

          </div>
          <div class="common_index_rightwidth">
            <div class="common_title_box">
              <span class="title">知识普法</span>
            </div>

            <div class="pfbox">
              <div class="pfmain flex_around">
                
                  <div v-for="(item,i) in LawKnowledgelist1" :key="i" @click.stop="tourl('/wenfa?title=' + item.name)" class="p">
                      <img src="@/assets/img/pf.png" alt="" >
                      <span>{{item.name}}</span>
                  </div>
               
              </div>
              <div class="see" @click.stop="tourl('/wenfa')">查看全部</div>
            </div>
          </div>
        </div>
       
      </div>
    </div>
    <!-- 页脚 -->
    <bottomfooter></bottomfooter>
  </div>
</template>

<script>
import topheader from '@/components/TopHeader.vue'
import bottomfooter from '@/components/Footer.vue'
import zixun  from '@/components/zixun'
import pingjia  from '@/components/pingjia'
import wenda  from '@/components/wenda'
import news  from '@/components/news'
import jinpails  from '@/components/jinpails'


import apiUrl from '@/api/main'


export default {
  name: '',
  components: { topheader, bottomfooter, zixun, pingjia , wenda, news,jinpails},
  data() {
    return {
      activeIndex: '1',
      // 热门领域 ==== start
      ishotleft:false,// 鼠标是否在左边
      hottype:'',
      // show1:false, 
      // show2:false,
      // show3:false,
      // show4:false,
      // show5:false,
      // show6:false,
      // show7:false,
      // 热门资讯
      zanItem:'',
      xingItem:'',

      LawKnowledgelist:[], //热门领域
      LawKnowledgelist1:[], //热门领域知识普法
      hotlist:[], // 热门领域子元素
      hottitle:'', // 子元素标题

      wendalist:[], // 最新咨询
      pingjialist:[], // 最新评价
      informationlist: [], //热门资讯
      fuwuid:'', // 热门领域(精选服务)id
      swipers:[], // 轮播图
    }
  },

  // filters: {
  //   // 过滤器处理价格
  //   currency(val) {
  //     val = Number(val)
  //     if (!val) return '0.00'
  //     return val.toFixed(2)
  //   }
  // },
  created() {
   
  },
 

  mounted() {

    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      window.location.replace("https://h5.qw5599.com/#/"); 
    } else if (/(Android)/i.test(navigator.userAgent)) {
      window.location.replace("https://h5.qw5599.com/#/"); 
    } else {
        this.getlsLunbolist(); // 轮播图
        this.getlsLawKnowledgelist(); // 热门领域
        this.getlsLawKnowledgelist1(); // 热门领域知识普法
        this.getwendalist(); // 最新咨询
        this.getpingjialist(); // 最新评价
        this.getinformationlist(); // 热门资讯
        // 跳转回顶部
        document.documentElement.scrollTop = 0;
    };
   
  },

  methods: {
  
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    tourllydetails(item){
      if(!this.fuwuid && this.LawKnowledgelist.length > 0){
        this.fuwuid = this.LawKnowledgelist[0].id;
      }
      this.$router.push({
        path: '/lydetails?id=' + this.fuwuid + '&subid=' + item.id
      })
    },
    // 热门领域 鼠标移入
    changeChan(e,pId,hottitle){
   
      this[`show${e}`] = true;
      this.hottype = e;
      this.gethostlist(pId);
      this.fuwuid = pId;
      this.hottitle = hottitle;
    },
  
    changeLeft(){
      this.ishotleft = true;
    },
    changeLeftBack(){
      this.ishotleft = false;
      this.hottype = '';
    },

    // 接口========
    // 热门领域
    getlsLawKnowledgelist() {
      let data = {
        pageSize: 999,
				pageNum: 1,
				pId: 0,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
          this.LawKnowledgelist = res.rows;
          if(res.rows.length > 0){
            this.gethostlist(res.rows[0].id);
          }
      })
    },
    // 热门领域知识普法
    getlsLawKnowledgelist1() {
      let data = {
        pageSize: 10,
				pageNum: 1,
				pId: 0,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
          this.LawKnowledgelist1 = res.rows;
      })
    },
    // 根据领域获取子元素
    gethostlist(pId) {
      let data = {
				pId: pId,
        userId: localStorage.getItem('userId') || ''
      }
      apiUrl.lsLawKnowledgelist(data).then((res) => {
          this.hotlist = res.rows;
      })
    },

    // 获取最新咨询
    getwendalist() {
      let data = {
				pageSize: 8,
				pageNum: 1,
      }
      apiUrl.wendalist(data).then((res) => {
          this.wendalist = res.rows;
      })
    },
    // 获取最新评价
     getpingjialist() {
      let data = {
				// pageSize: 5,
				// pageNum: 1,
      }
      apiUrl.zuixinorderlist(data).then((res) => {
          this.pingjialist = res.rows;
          this.$refs.newpingjia.open();
      })
    },
    // 获取热门资讯 
    getinformationlist() {
      let data = {
        userId: localStorage.getItem('userId'),
        pageSize: 10,
				pageNum: 1,
      }
      apiUrl.lsInformationlist(data).then((res) => {
          this.informationlist = res.rows;
      })
    },
    // 获取轮播图
    getlsLunbolist() {
      apiUrl.lsLunbolist().then((res) => {
        if(res.rows.length > 0){
          res.rows.forEach(item => {
            this.swipers.push({
              image:item.photo,
              title:item.title
            })
          });
          this.swipers.push(this.swipers[0]);

          if(res.rows.length > 1){
            this.swipers.push(this.swipers[1]);
          }
        }
      })
    },
  }
}
</script>

<style lang='scss' >
.indexbox1{
  background: #F8F8F8;

.main{
  margin-top: 20px;
}
// 热门领域
.hotbox{
  .hotbox_left{
    // width: 183px;
    // height: 360px;
    // border-radius: 8px;
    width:220px;
    height: 432px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 20px 0 25px;
    overflow: auto;

    div{
      // font-size: 17px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      border-left: 7px solid #305BFE;
      // text-align: center;
      margin-bottom: 10px;
      // padding-left: 61px;
      padding-left: 48px;
    }
    p{
      // font-size: 13px;
      // line-height: 37px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 43px;
      display: flex;
      align-items: center;
      // justify-content: center;
      padding-left: 48px;
      cursor: pointer;
      img{
        // width:17px;
        // height: 17px;
        // margin-right: 8px;
        width:20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .active{
      color: #305BFE;
      background: #E9EDFB;
    }
  }
  .hotbox_left::-webkit-scrollbar{
    display: none;
  }
  .hotbox_center{
    // width: 727px;
    // height: 363px;
    // margin: 0 13px;
    // border-radius: 8px;
    width: 872px;
    height: 436px;
    margin: 0 16px;
    border-radius: 10px;
    .bgimg{
      // width: 727px;
      // height: 363px;
      // border-radius: 8px;
      width: 872px;
      height: 436px;
      border-radius: 10px;
    }
    .hotbox_center_main{
      // width: 727px;
      // height: 363px;
      width: 872px;
      height: 436px;
      background-color: #fff;
      padding: 33px;
      box-shadow: 0px 2px 5px 0px rgba(51,63,109,0.24);
      border-radius: 8px;
      .ly_title{
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
      }
      .sapnbox{
        a{
          border-radius: 17px;
          border: 1px solid #CCCCCC;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          padding: 8px 17px;
          margin-right: 13px;
          margin-bottom: 13px;
        }
        a:hover{
          border: 1px solid #305BFE;
          color: #305BFE;
          cursor: pointer;
        }
       
      }
    }
  }
  .hotbox_right{
    // width:263px;
    // height: 363px;
    width:316px;
    height: 436px;
    cursor: pointer;
    position: relative;

    .bgright{
      // width:263px;
      // height: 363px;
      width:316px;
      height: 436px;
    }
    >div{
      position: absolute;
      // width: 137px;
      // top: 135px;
      // height: 40px;
      // line-height: 40px;
      top: 162px;
      left: 0;
      right: 0;
      width: 164px;
      height: 48px;
      line-height: 48px;
      margin: 0 auto;
      font-size: 18px;
    }
  }

}

// 服务保障
.fubox{
  background: #F8F8F8;
  margin: 24px 0 24px;
  img{
    // width:162px;
    width:183px;
    // height: 47px;
    height: 52px;
  }
}

// 免费律师
.mfbox{
  // height: 430px;
  background: #F1F5FE;
  .mfboxleft{
    padding: 40px 0;
    .text{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #777777;
      margin: 12px  0 23px;
    }
  }
  .mfboxright{
    border-radius: 13px;
    img{
      width: 586px;
      height: 330px;
      background: #0471B5;
      border-radius: 13px;
    }
  }
}

// 最新咨询和最新评价
.zxbox{
  // height: 628px;
  padding: 48px 0 0;
  background-color: #fff;
  .base_w{
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 56px;
  }

}

 // 热门资讯
 .rmbox{
    background-color: #fff;
    padding-top: 41px;
    .rmzxbox{
      margin-top: 13px;
    }
  }

  // 知识普法
  .pfbox{
    width: 586px;
    height: 454px;
    background: #F8F8F8;
    border-radius: 8px;
    padding: 40px 20px;
    margin-top: 16px;
    margin-bottom: 120px;
    box-sizing: border-box;
    .pfmain{
      flex-wrap: wrap;
      .p{
        width: 50%;
        margin-bottom: 37px;
        // text-align: center;
        cursor: pointer;
        padding-left: 84px;
        box-sizing: border-box;
        img{
          width: 15px;
          height: 15px;
          margin-right: 16px;
        }
        span{
          font-size: 20px;
          color: #333333;
        }
      }
    }
    .see{
      width: 100%;
      text-align: center;
      border-top: 1px solid #E6E6E6;
      height: 86px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #777777;
      line-height: 86px;
      cursor: pointer;
    }
  }

  .qu{
    overflow: auto;
    height: 510px;
  }
  
  .qu::-webkit-scrollbar{
    display: none;
  }

  .el-carousel__indicators{
    display: none !important;
  }

}

</style>